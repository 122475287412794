@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400&display=swap);
:root {
  --active: #3ec07a;
  --send: #18b7ff;
  --dark-inverse: #ffffff;
  --completed: #85cf15;
  --cancelled: #e02929;
  --breakdown: #f64e60;
  --pending: #74c2ee;
  --btn-primary: rgba(68, 100, 144, 1);
  --btn-primaryHover: rgba(4, 5, 138, 1);
  --invoiced: #ccc165;
  --dispatched: #c499f3;
  --text-primary: rgba(68, 100, 144, 1);
}

body {
  font-family: "Nunito", sans-serif !important;
}

.auth-container {
  background: #f5faff !important;
}

.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-submenu-title .anticon + span {
  font-weight: bold;
  position: relative;
  top: 3px;
}

.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading .ant-spin {
  color: #3e79f7;
}

/*  */
a {
  text-decoration: unset;
}

.btn {
  font-size: 12px !important;
}

.ataggreen-btn {
  background: rgba(68, 100, 144, 1) !important;
  color: #fff !important;
}

.ant-select {
  width: auto;
}

.ant-table-tbody > tr > td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-picker {
  width: 100%;
  border-radius: 5px !important;
  border: 1px solid rgba(39, 74, 138, 1) !important;
  background-color: #f6fcff !important;
  font-size: 12px !important;
}

.form-innersection .ant-input {
  border-radius: 4px;
  border: 1px solid rgba(39, 74, 138, 1);
  background-color: #f6fcff;
  font-size: 14px !important;
  color: rgba(34, 43, 69, 1);
  font-weight: 600;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 5px !important;
  border: solid 1px rgba(68, 100, 144, 1) !important;
  background-color: #f6fcff !important;
  font-size: 12px !important;
  color: rgba(68, 100, 144, 1);
}

.right-inner-addon {
  position: relative;
}

.right-inner-addon input {
  padding-right: 35px !important;
  font-size: 12px !important;
  border-radius: 5px;
  border: solid 1px #b5b5b5;
  background-color: #fff;
  height: 36px;
}

.right-inner-addon i {
  position: absolute;
  right: 0px;
  padding: 12px 10px;
  pointer-events: none;
  font-size: 10px;
}

.table-filterbtn:hover {
  color: white !important;
}

.ant-btn[disabled]:hover {
  background: #f7f7f8 !important;
}

.table-filterbtn {
  background-color: #4a789b !important;
  color: white !important;
  font-size: 12px !important;
  letter-spacing: 0.5px;
  width: 100%;
  border-radius: 5px;
}

.client-table .ant-table-thead .ant-table-cell {
  background: #ededed;
  /*width:150px;*/
}

.table-header table thead tr th:last-child {
  text-align: center;
}

.app-content {
  background: #f5faff;
}

.innerCtr {
  background-color: #fff;
  padding: 20px 20px 0px;
  border-radius: 10px;
}

.notactive-breadcrumb {
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.14;
  letter-spacing: normal;
  text-align: left;
  color: #302f46;
  font-size: 14px;
}

.active-breadcrumb {
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.14;
  letter-spacing: normal;
  text-align: left;
  color: rgba(68, 100, 144, 1) !important;
  font-size: 14px;
}

.breadcrumb-ctr i {
  padding: 0px 7px;
}

.form-header {
  background-color: rgba(68, 100, 144, 1);
  padding: 10px;
  color: white;
  line-height: 1.6;
}

.form-header span {
  font-size: 16px;
  padding-bottom: 10px;
}

.form-innersection {
  background-color: #fff;
  padding: 10px;
  /* border: 1px solid #e6ebf1; */
}

.form-innersection1 {
  border: none !important;
  padding: 0px !important;
}

span {
  font-size: 13px;
}

.forminner-head {
  font-weight: bold;
  letter-spacing: 0.5px;
  border-bottom: 1px solid #c9c9c9;
  padding-bottom: 7px;
  margin-bottom: 15px;
}

.ant-form-item-label > label {
  color: rgba(34, 43, 69, 1) !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}

.form-label span {
  color: #e15928;
  font-size: 11px;
  font-weight: bold;
  padding-left: 5px;
}

.steps-action {
  text-align: right !important;
}

.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot,
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: rgba(68, 100, 144, 1) !important;
}

.ant-steps-item-finish .ant-steps-item-icon,
.ant-steps-item-process .ant-steps-item-icon {
  border-color: rgba(68, 100, 144, 1) !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(68, 100, 144, 1) !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after,
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: rgba(68, 100, 144, 1) !important;
}

.steps-action .ant-btn-primary {
  background-color: rgba(68, 100, 144, 1);
  border-color: rgba(68, 100, 144, 1);
}

.form-headicons i {
  color: white !important;
}

.det-head {
  font-size: 13px;
  font-weight: bold;
  line-height: 2.5;
  text-align: left;
  color: #302f46;
}

.det-subheadgreen {
  font-size: 13px;
  font-weight: bold;
  line-height: 2.5;
  text-align: left;
  color: rgba(68, 100, 144, 1);
}

.det-subhead {
  font-size: 13px;
  font-weight: normal;
  line-height: 2.5;
  text-align: left;
  color: #302f46;
}

.view-ctr i {
  border-radius: 5px;
  border: solid 1px #3ba9e8;
  background-color: #fff;
  color: #3ba9e8;
  padding: 5px;
}

.edit-ctr i {
  border-radius: 5px;
  border: solid 1px #e9b437;
  background-color: #fff;
  color: #e9b437;
  padding: 5px;
}

.delete-ctr i {
  border-radius: 5px;
  border: solid 1px #d93434;
  background-color: #fff;
  color: #d93434;
  padding: 5px;
}

.iconwith-select {
  width: 88% !important;
}

.iconwith-select
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 0px !important;
}

.orderstatus-tabledash {
  overflow-x: auto;
}

.orderstatus-tabledash table {
  border-collapse: separate;
  width: 100%;
  border-spacing: 0 5px;
}

.orderstatus-tabledash table tr td {
  font-size: 14px;
  color: #3d3d3d;
  border-bottom: 1.3px solid #eaeaea;
  padding: 0px 5px 10px 5px;
}

.cusdet-table th {
  background-color: #dbe9ff;
  color: #302f46;
  font-size: 14px;
  padding: 13px 5px;
}

.cusdet-table tr td {
  line-height: 35px;
}

.cusdet-table th {
  font-size: 12px !important;
}

.cusdet-table td {
  font-size: 12px !important;
}

.ant-modal-body {
  /* max-height: 270px; */
  overflow: auto;
}

/* quote style */

.qstatus-all {
  border-radius: 5px;
  background-color: #595959;
  border: solid 1px #595959;
  color: #fff;
  text-align: center;
  font-size: 12px;
}

.qstatus-active {
  border-radius: 5px;
  border: solid 1px #3ba9e8;
  color: #3ba9e8;
  text-align: center;
  font-size: 12px;
}

.qstatus-complete {
  border-radius: 5px;
  border: solid 1px #4a9b79;
  color: #4a9b79;
  text-align: center;
  font-size: 12px;
}

.qstatus-cancel {
  border-radius: 5px;
  border: solid 1px #e02929;
  color: #e02929;
  text-align: center;
  font-size: 12px;
}

.qstatus-save {
  border-radius: 5px;
  border: solid 1px #b8a926;
  color: #b8a926;
  text-align: center;
  font-size: 12px;
}

.qstatus-dispatch {
  border-radius: 5px;
  border: solid 1px #8d3be8;
  color: #8d3be8;
  text-align: center;
  font-size: 12px;
}

.qstatus-transmit {
  border-radius: 5px;
  border: solid 1px #e89d3b;
  color: #e89d3b;
  text-align: center;
  font-size: 12px;
}

.qstatus-delivered {
  border-radius: 5px;
  border: solid 1px #a3e83b;
  color: #a3e83b;
  text-align: center;
  font-size: 12px;
}

.cancel-btn {
  border-radius: 5px;
  border: solid 1px rgba(68, 100, 144, 1) !important;
  color: rgba(68, 100, 144, 1) !important;
  background-color: transparent !important;
}

.reveneadd-btn {
  background-color: #393868 !important;
  color: #fff !important;
}

.blue-btn {
  background-color: #3ba9e8 !important;
  color: #fff !important;
}

.red-btn {
  background-color: #e85162 !important;
  color: #fff !important;
}

.liability-btn {
  background-color: #4a789b !important;
  color: #fff !important;
}

.dash-headerbanner {
  height: 140px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(/static/media/Truck_Cover.d90c5a33.png);
}

.truckcover-innerCtr {
  padding: 45px;
}

.auth-container {
  height: 100vh;
  background: #e8e8e8ba;
}

.ant-switch-checked {
  background-color: rgba(68, 100, 144, 1) !important;
}

.samebill-ctr .ant-form-item {
  margin-bottom: 0px !important;
}

.form-label {
  color: rgba(68, 100, 144, 1);
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

/* dispatch style */

.warning-Ctr span {
  color: #d29020;
  margin-right: 5px;
}

.maproute-left i {
  color: #4a9b79;
  font-size: 22px;
}

.maproute-left {
  border-bottom: 1px solid lightgrey;
}

.maproute-leftctr {
  max-height: 300px;
  overflow: auto;
}

.maproutedetails-ctr {
  height: 300px;
}

.segmentmap-ctr {
  height: 140px;
}

.routeplan-inneractive {
  background: #e8fff5 0% 0% no-repeat padding-box;
  border: 1px solid #e8fff5 !important;
  border-radius: 5px;
}

.routeplan-inner {
  padding: 15px;
  border: 1px solid lightgrey;
  border-radius: 5px;
}

.routefrom i {
  color: #4a9b79;
}

.routefrom span {
  color: #4a9b79;
  font-weight: bold;
}

.routeto i {
  color: #4a9b79;
}

.route-innerdetails span {
  font-size: 11px !important;
}

.routerplan-ctr {
  max-height: 360px;
  overflow: auto;
}

.route-innerdetails span {
  font-size: 11px !important;
}

.cancel-color {
  color: #e02929;
}

.complete-color {
  color: rgba(68, 100, 144, 1) !important;
}

.datefilter .left-icon i {
  color: rgba(68, 100, 144, 1);
  border: 1px solid #c4e0bc;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  padding: 6px;
  font-size: 18px;
}

.datefilter .right-icon i {
  color: rgba(68, 100, 144, 1);
  border: 1px solid #c4e0bc;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  padding: 6px;
  font-size: 18px;
}

.datefilter input {
  border: none;
  color: #4a9b79;
  font-weight: bold;
  padding: 8px 0px 4.2px 0px;
  text-align: center;
  border-top: 1px solid #c4e0bc;
  border-bottom: 1px solid #c4e0bc;
}

/* dashboard style */

.customer-ctr {
  border-radius: 15px;
  background-image: linear-gradient(
    59deg,
    #22d5da 42%,
    rgba(83, 136, 231, 0.79) 82%
  );
  text-align: center;
  padding: 25px 0px;
}

.rowinner-span {
  color: white;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.dashmenu-ctr i {
  font-size: 26px;
  color: white;
}

.quotes-ctr {
  border-radius: 15px;
  background-image: linear-gradient(62deg, #f5519e 24%, #fd6f7c 79%);
  text-align: center;
  padding: 25px 0px;
}

.order-ctr {
  border-radius: 15px;
  background-image: linear-gradient(63deg, #41e098 25%, #3ab8b1 79%);
  text-align: center;
  padding: 25px 0px;
}

.employee-ctr {
  border-radius: 15px;
  background-image: linear-gradient(
    233deg,
    #b5da22 83%,
    rgba(187, 236, 23, 0.79) 41%
  );
  text-align: center;
  padding: 25px 0px;
}

.eqp-ctr {
  border-radius: 15px;
  background-image: linear-gradient(62deg, #ffd641 21%, #ff8d54 72%);
  text-align: center;
  padding: 25px 0px;
}

.vendor-ctr {
  border-radius: 15px;
  background-image: linear-gradient(62deg, #fbc2eb 25%, #a6c1ee 74%);
  text-align: center;
  padding: 25px 0px;
}

.orderstatus-ctr {
  object-fit: contain;
  border-radius: 15px;
  background-color: #fff;
  padding: 25px;
}

.checkcleartable {
  overflow-x: auto;
  width: 100%;
}

.checkcleartable table {
  border-collapse: separate;
  width: 100%;
  border-spacing: 0 20px;
}

.checkcleartable table tr td {
  font-size: 14px;
  color: #3d3d3d;
  border-bottom: 1.3px solid #eaeaea;
  padding: 0px 15px 10px 5px;
}

.orderstatus-tabledash {
  overflow-x: auto;
}

.orderstatus-tabledash table {
  border-collapse: separate;
  width: 100%;
  border-spacing: 0 5px;
}

.orderstatus-tabledash table tr td {
  font-size: 14px;
  color: #3d3d3d;
  border-bottom: 1.3px solid #eaeaea;
  padding: 0px 5px 10px 5px;
}

.new-head span {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: left;
  color: #3d3d3d;
}

.tablelink a {
  color: #3ba9e8;
  border-bottom: 1px solid #3ba9e8;
  font-weight: bold;
}

.tablelink a:hover {
  color: #3ba9e8;
  border: none;
}

.tablestatus .hold {
  border-radius: 8px;
  background-color: #daf2ff;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3ba9e8 !important;
  padding: 5px;
  font-size: 13px;
}

.tablestatus .transmit {
  border-radius: 8px;
  background-color: #ffefdb;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e89d3b !important;
  padding: 5px;
  font-size: 13px;
}

.tablestatus .dispatch {
  border-radius: 8px;
  background-color: #f5ebff;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8d3be8 !important;
  padding: 5px;
  font-size: 13px;
}

.livetrack-btn {
  border-radius: 5px;
  background-color: rgba(68, 100, 144, 1);
  text-align: center;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 5px;
}

a:hover {
  text-decoration: none;
}

.orderstatus-tabledash table tr td {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tablestatus {
  max-width: none !important;
}

.active-btn {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.active-btn button {
  border-radius: 8px;
  background-color: rgba(68, 100, 144, 1);
  font-size: 12px !important;
}

.notactive-btn {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.notactive-btn button {
  border-radius: 8px;
  border: 1px solid rgba(68, 100, 144, 1);
  color: #4a9b78 !important;
  font-size: 12px !important;
  background-color: transparent !important;
  font-weight: bold !important;
}

.addsetting-style {
  margin-top: 43px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: rgba(68, 100, 144, 1);
}

.nav-link {
  color: #000000;
}

.checkcleartable th:last-child {
  position: unset;
  box-shadow: none;
}

.checkcleartable td:last-child {
  position: unset;
  box-shadow: none;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(219, 233, 255, 1) !important;
}

.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-submenu:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-expand-icon {
  color: rgba(68, 100, 144, 1) !important;
}

.ant-menu-inline .ant-menu-item::after,
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after {
  border-right: 3px solid rgba(68, 100, 144, 1) !important;
}

.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: rgba(68, 100, 144, 1) !important;
}

.ant-pagination-item-active {
  background: rgba(68, 100, 144, 1) !important;
  color: white;
}

.ant-menu-item a:hover,
.ant-menu-item > .ant-badge a:hover,
.ant-menu-submenu-selected {
  color: rgba(68, 100, 144, 1) !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: rgba(68, 100, 144, 1) !important;
}

.ant-tabs-ink-bar {
  background: rgba(68, 100, 144, 1) !important;
}

.ant-tabs-tab:hover {
  color: #000000;
}

.ant-table tfoot > tr > td,
.ant-table tfoot > tr > th,
.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  padding: 10px;
  font-size: 13px;
}

.ant-picker,
.ant-picker-input > input {
  font-size: 12px;
}

.login-Ctr .ant-card-body {
  padding: 0px !important;
}

.loginfull-Ctr {
  background: #f5faff;
}

.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-submenu-title .anticon + span {
  font-weight: bold;
}

/* .ant-steps-item-description{
  margin-left: 30%;
  margin-top: -25px;
}
.ant-steps-item-title{
  white-space: nowrap;
  width: 28%;
  overflow: hidden;
  text-overflow: ellipsis;
} */
@media only screen and (max-width: 767px) {
  .dash-headerbanner {
    box-shadow: inset 10px 111px 50px #212121a1;
  }

  .truckcover-innerCtr {
    padding: 40px 20px;
    color: white;
  }
}

.ant-form-item-explain.ant-form-item-explain-error {
  color: #ff6b72;
  font-size: 12px;
}

.deletepopup .ant-modal-header {
  display: none;
}

.deletepopup .ant-modal-footer {
  border: none;
}

.steps-content {
  padding: 0 20px;
}

.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label,
.ant-form-vertical .ant-form-item-label {
  padding-bottom: 0;
}

.ant-form-item-label > label {
  height: 22px;
}

span.text-danger {
  font-size: 12px !important;
  font-weight: bold;
}

.ant-steps-item-description,
.ant-steps-item-subtitle {
  font-size: 12px;
  padding-bottom: 30px !important;
}

.modal-1 .ant-modal-footer {
  display: none;
}

.modal-1 .ant-modal-body {
  padding-bottom: 0px;
}

.modal-1 .footer-popup {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px;
  background: white;
  padding: 10px 0px;
  text-align: right;
}

.table-wraps {
  min-height: 500px;
}

.landing .wrapper {
  padding: 0em 2em 2em 2em;
}

.landing .header {
  background-color: #fff;
  color: #000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: "Teko", sans-serif;
  text-transform: uppercase;
}

.landing .header .logo {
  flex: 3 0;
  font-weight: bold;
  align-items: center;
  font-size: 2em;
}

.landing .header .othermenus {
  flex: 1 0;
  font-weight: bold;
  align-items: center;
}

.landing .header ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5em;
}

.landing .header ul li a {
  color: rgba(68, 100, 144, 1);
}

.landing .main {
  background-color: rgba(68, 100, 144, 1);
  min-height: 400px;
}

.landing .main .mainhead {
  padding: 4em;
}

.landing .main .mainhead .buttonmenu {
  width: 4em;
  height: 4em;
  background: #fff;
  border-radius: 50%;
  transition: all 0.9s cubic-bezier(0.42, 0, 0.01, 1.93);
  transform: rotateY(180deg) rotate(0);
}

.landing .main .mainhead .buttonmenu:hover {
  transform: rotateY(180deg) rotate(180deg);
}

.landing .main .mainhead .buttonmenu:hover .midline::after {
  width: 1.5em;
}

.landing .main .mainhead .buttonmenu:hover .midline::before {
  width: 2.5em;
}

.landing .main .mainhead .buttonmenu .midline {
  width: 2.5em;
  height: 6px;
  background-color: #000;
  border-radius: 8px;
  margin: 1.8em 1em 1em 0.8em;
  position: absolute;
}

.landing .main .mainhead .buttonmenu .midline::before,
.landing .main .mainhead .buttonmenu .midline::after {
  transition: all 0.6s ease-in-out;
  width: 2.5em;
  height: 6px;
  background-color: #000;
  border-radius: 8px;
  margin: 0px auto;
  content: "";
  display: block;
  position: absolute;
}

.landing .main .mainhead .buttonmenu .midline::after {
  margin-top: -10px;
}

.landing .main .mainhead .buttonmenu .midline::before {
  margin-top: 10px;
  width: 1.5em;
}

.landing dl,
.landing ol,
.landing ul {
  margin-top: 0;
  margin-bottom: 0;
}

.landing .contents {
  color: #000;
}

.landing .contents h1,
.contents .title {
  font-family: "Teko", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
}

.landing .contents h1 {
  font-size: 8em;
  margin-top: 40px;
  color: #000;
}

.landing .contents .spacer {
  height: 4em;
  width: 3px;
  border-radius: 6px;
  background: #000;
  margin: 0px 4px 3em 4px;
}

.landing .contents .title {
  font-size: 4em;
  width: 300px;
  line-height: 0.8em;
  margin-bottom: 0.8em;
}

.landing .contents .text {
  width: 70%;
}

.landing .contents img.side {
  position: relative;
  margin-top: -4em;
  width: 100%;
}

.landing .contents .button_next {
  color: #fff;
  background-color: #e22112;
  font-weight: bold;
  padding: 8px 25px;
  display: inline-block;
  margin-left: 72%;
  margin-top: 100px;
  transition: all 0.3s ease-in-out;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0);
}

.landing .contents .button_next:hover {
  box-shadow: 3px 2px 10px rgba(0, 0, 0, 0.45);
}

.landing .contents .button_next .fa {
  display: inline;
  margin-left: 10px;
}

.reselect {
  height: 41px;
  width: 100%;
  border-radius: 5px !important;
  border: solid 1px rgba(68, 100, 144, 1) !important;
  background-color: #f6fcff !important;
  font-size: 14px !important;
  color: rgba(68, 100, 144, 1);
}

.reselectctr label {
  color: rgba(68, 100, 144, 1);
  font-size: 13px;
  font-weight: 500;
}

.filter-btn {
  border-radius: 3px !important;
}

.filter-btn .ant-btn-default {
  background: #dfebff !important;
  border-color: rgba(68, 100, 144, 1) !important;
  color: rgba(68, 100, 144, 1);
}

.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  padding-top: 0;
  padding-bottom: 0;
}

.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  padding-top: 0;
  padding-bottom: 0;
}

.btn-primary-custom {
  background: rgba(68, 100, 144, 1) !important;
  border-color: rgba(68, 100, 144, 1) !important;
  color: #fff !important;
}

.react-datepicker__input-container input {
  border-radius: 4px;
  border: 1px solid rgba(39, 74, 138, 1);
  background-color: #f6fcff;
  font-size: 14px !important;
  color: rgba(34, 43, 69, 1);
  font-weight: 600;
  width: 100%;
  padding: 8.5px 11px;
}

#popselect {
  width: 87%;
  border-right: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.select-iconCtr {
  border-right: 1px solid rgba(68, 100, 144, 1);
  border-top: 1px solid rgba(68, 100, 144, 1);
  border-bottom: 1px solid rgba(68, 100, 144, 1);
  padding: 10.5px 13px 9.6px 13px;
  background: #f6fcff;
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.select-iconCtr i {
  color: rgba(68, 100, 144, 1);
}

.commodities-modal .ant-modal-body {
  max-height: 100%;
}

.btn-primary-danger {
  background: #ff4d4f4a !important;
  border-color: #ff4d4f4a !important;
  color: #ff4d4f !important;
}

.btn-danger-custom {
  background: #ff4d4f4a !important;
  border-color: #ff4d4f4a !important;
  color: #ff4d4f !important;
}

.btn-primary-custom1 {
  background: rgba(219, 233, 255, 1) !important;
  border-color: rgba(219, 233, 255, 1) !important;
  color: #00ab6f;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: rgba(68, 100, 144, 1) !important;
  border-color: rgba(68, 100, 144, 1) !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: rgba(68, 100, 144, 1) !important;
}

.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: rgba(68, 100, 144, 1) !important;
}

.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: rgba(4, 5, 138, 1) !important;
}

.fs-16 {
  font-size: 16px;
}

.map-container {
  height: 100%;
}

.mapSidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 280px;
  height: 100%;
  /* background-color:#4a9b7994; */
  background-color: rgb(219 230 255 / 85%);
}

.mapSidebar .ant-tabs-nav {
  background-color: rgba(68, 100, 144, 1);
}

.mapSidebar .ant-tabs-tab {
  padding: 10px 10px;
  margin-right: 0px;
}

.mapSidebar .h-icon {
  font-size: 13px;
  text-transform: uppercase;
}

.mapSidebar .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}

.mapSidebar .sidebar-head {
  padding: 10px 15px;
  background-color: #446490;
}

/*.mapSidebar .sidebar-head > h4{
  padding: 0;
  margin: 0;
  font-size: 13px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Nunito";
}
.mapSidebar .sidebar-head > p{
  padding: 0;
  margin: 0;
  font-size: 12px;
  color: #fff;
}*/
.mapSidebar ul.listfy {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.mapSidebar ul.listfy > li {
  display: block;
  width: 100%;
  margin-bottom: 2px;
  background-color: rgba(121 169 245 / 32%);
  padding: 4px 15px;
  cursor: pointer;
}

.mapSidebar ul.listfy > li > h4 {
  font-size: 13px;
  font-weight: 600;
  padding: 0;
  margin: 0;
  color: #000;
}

.mapSidebar ul.listfy > li > p {
  font-size: 12px;
  padding: 0;
  margin: 0;
  color: #000;
}

.mapboxgl-popup {
  top: -10px !important;
}

.process-filterdatepicker .ant-picker-range {
  width: 25%;
}

.process-filterdatepicker .anticon-search {
  font-size: 16px;
}

.qo-ch {
  background: #c3f4e1;
  font-weight: bold;
  padding: 0px 5px;
  color: rgba(68, 100, 144, 1);
  font-size: 12px;
}

.calendar-event-panel .ant-card-body {
  padding: 10px;
}

.stats-bg-active {
  border-color: var(--active) !important;
  color: var(--active) !important;
}

.stats-bg-send {
  border-color: var(--send) !important;
  color: var(--send) !important;
}

.stats-bg-completed {
  border-color: var(--completed) !important;
  color: var(--completed) !important;
}

.stats-bg-invoiced {
  border-color: var(--invoiced) !important;
  color: var(--invoiced) !important;
}

.stats-bg-dispatched {
  border-color: var(--dispatched) !important;
  color: var(--dispatched) !important;
}

.stats-bg-cancelled {
  border-color: var(--cancelled) !important;
  color: var(--cancelled) !important;
}

.stats-bg-breakdown {
  border-color: var(--breakdown) !important;
  color: var(--breakdown) !important;
}

.stats-bg-pending {
  border-color: var(--pending) !important;
  color: var(--pending) !important;
}

.ant-input-number {
  width: auto;
}

.pay-inputbtn .pay-text-input {
  width: 25%;
  border-radius: 0px;
  border: 1px solid rgba(68, 100, 144, 1);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pay-inputbtn .pay-btn {
  background-color: rgba(68, 100, 144, 1);
  border-radius: 0px;
  padding: 10px 5px;
  color: white;
  border: 1px solid rgba(68, 100, 144, 1);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.hovermenu-icon a span {
  font-size: 16px;
  margin-right: 5px;
}

.dispatch-innerfilter .ant-btn {
  height: 25px !important;
}

.revenue-units {
  border-right: 1px solid #eaeaea;
}

.live-tracking-list-title .ant-list-item-meta-title,
.ant-list-item-meta-description {
  color: #fff0f0;
}

.live-tracking-card {
  border-radius: 0px;
}

.ant-tag {
  text-transform: capitalize;
}

.dispatch-timeline {
  padding-left: 150px;
  display: block;
  list-style-type: none;
  margin-bottom: 60px;
}

.dispatch-timeline > li {
  position: relative;
}

.dispatch-timeline > li:before {
  content: "";
  position: absolute;
  left: 0;
  width: 2px;
  height: 100%;
  background: rgba(219, 233, 255, 1) !important;
}

.dispatch-timeline .order-info {
  position: absolute;
  left: -150px;
  width: 130px;
  height: auto;
  top: 28px;
  text-align: right;
}

.dispatch-timeline1 {
  padding-left: 150px;
  display: block;
  list-style-type: none;
  /* margin-bottom: 60px; */
}

.dispatch-timeline1 > li {
  position: relative;
}

.dispatch-timeline1 > li:before {
  content: "";
  position: absolute;
  left: 0;
  width: 2px;
  height: 100%;
  background: rgba(219, 233, 255, 1) !important;
}

.dispatch-timeline1 .order-info {
  position: absolute;
  left: -150px;
  width: 130px;
  height: auto;
  top: 28px;
  text-align: right;
}

.shipments-list {
  padding: 20px 0px 0px;
  margin: 0;
  list-style-type: none;
}

.shipments-list > li {
  padding: 10px 20px 10px;
  position: relative;
}

.shipments-list > li:before {
  content: "";
  position: absolute;
  left: -6px;
  width: 14px;
  height: 14px;
  background-color: #ddd;
  border-radius: 50%;
}

.shipments-list > li.shipment-delivered {
  background-color: rgba(219, 233, 255, 1);
}

.shipments-list > li.shipment-returned {
  background-color: rgba(68, 100, 144, 1);
}

.shipments-list > li.shipment-delivered:before {
  background-color: rgba(68, 100, 144, 1);
}

.shipments-list > li.shipment-returned:before {
  background-color: #ff6b72;
}

.order-info h6,
.shipments-list > li .list-card h6 {
  padding: 0;
  margin: 0;
  font-size: 10px;
  text-transform: uppercase;
  color: #999;
}

.order-info p,
.shipments-list > li .list-card p {
  padding: 0;
  margin: 0;
  font-size: 12px;
  color: #000;
}

.shipmentadd-ctr {
  background: #4a9b7930;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.shipmentadd-ctr .ant-form-item {
  margin-bottom: 0px;
}

.rounded-delete .anticon-delete {
  color: white;
  background: #ff00009c;
  padding: 10px;
  border-radius: 50%;
}

.dispatch-innerfilter .ant-btn {
  padding: 0px 3px;
}

.dispatch-innerfilter .ant-btn-icon-only {
  width: 20px !important;
}

.no-shipments {
  display: block;
  min-height: 88px;
}

.payment-ul {
  display: inline-block;
  padding: 15px;
  background-color: #eee;
  list-style-type: none;
  border-radius: 3px;
  width: 340px;
  margin-bottom: 15px;
}

.invoice-box {
  max-width: 800px;
  margin: auto;
  padding: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 13px;
  line-height: 20px;
  color: #555;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #446490;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #446490;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  font-size: 15px;
  color: rgb(232 232 232);
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 1px solid rgb(248, 244, 244);
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/* RTL */
.invoice-box.rtl {
  direction: rtl;
}

.invoice-box.rtl table {
  text-align: right;
}

.invoice-box.rtl table tr td:nth-child(2) {
  text-align: left;
}

.searchfilter-ul {
  margin: 0;
  padding: 0;
  display: inline-block;
  height: 36px;
  text-align: right;
}

.searchfilter-ul > li {
  display: inline-block;
}

.collect > h6 {
  font-size: 10px;
  margin: 0;
  display: block;
  padding: 0px 10px 0px 0px;
  text-transform: uppercase;
  color: #828282;
}

.collect > h3 {
  font-size: 16px;
  margin: 0;
  display: block;
  padding: 0px 10px 0px 0px;
}

.collect .collect-input {
  border-radius: 4px;
  border: 1px solid rgba(39, 74, 138, 1);
  background-color: #f6fcff;
  font-size: 14px !important;
  font-weight: 600;
}

/* To overwrite ANT D button primary color */
.ant-btn {
  border-radius: 4px !important;
}

.ant-btn-primary {
  background-color: var(--btn-primary) !important;
  border-color: var(--btn-primary) !important;
  border-radius: 4px !important;
  background: var(--btn-primary) !important;
}

.ant-btn[disabled] {
  background: #f7f7f8 !important;
  color: #d0d4d7;
  border-color: #e6ebf1 !important;
  text-shadow: none;
  box-shadow: none;
}

.ant-input-affix-wrapper-focused {
  border-color: var(--btn-primary);
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: var(--btn-primary);
}

/* To overwrite ANT D button hover primary color */
.ant-btn-primary:hover {
  background-color: var(--btn-primaryHover) !important;
  border-color: var(--btn-primaryHover);
}

.radio-border .ant-radio-button-wrapper {
  border-radius: 0 !important;
}

.radio-border .ant-radio-button-wrapper-checked {
  background-color: var(--btn-primary) !important;
  border-color: var(--btn-primary) !important;
  color: white;
}

.shipment-card {
  background-color: #f5faff;
}

.custom-datetimepicker .ant-picker-input > input {
  font-weight: bold;
  color: rgba(34, 43, 69, 1);
  /* visibility: hidden; */
  caret-color: transparent;
}

/* Pagination Style's overwrite */
.pagination-styles .ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-item {
  border-radius: 15% !important;
}

.pagination-styles svg {
  vertical-align: baseline;
}

/* To overwrite primary text color*/
.text-primary {
  color: var(--text-primary) !important;
}

.text-primary {
  color: rgba(68, 100, 144, 1) !important;
}

.anticon-ellipsis {
  font-size: 24px;
}

.ant-form-item-label > label {
  height: auto !important;
}

.notfound {
  position: relative;
  width: 100%;
  height: 500px;
}

.notfound .line {
  position: fixed;
  width: 550px;
  height: 2px;
  background: #90a4ae;
  left: calc(50vw - 275px);
  top: calc(50vh + 73px);
}

.notfound .bowl {
  background: #cfd8dc;
  position: fixed;
  width: 100px;
  height: 45px;
  border-radius: 5px 5px 50px 50px;
  left: calc(50vw - 25px);
  top: calc(50vh + 19px);
  border: 4px solid #161c1f;
  box-shadow: inset -5px 0 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.notfound .signwood {
  background: #5d4037;
  position: fixed;
  width: 17px;
  height: 150px;
  border-radius: 5px;
  left: calc(50vw - 80px);
  top: calc(50vh - 83px);
  border-top: 4px solid #3e2723;
  border-left: 4px solid #3e2723;
  border-bottom: 4px solid transparent;
  border-right: 4px solid #3e2723;
  box-shadow: inset -4px 0 0 rgba(0, 0, 0, 0.2);
}

.notfound .signtext {
  background: #8a6b47;
  position: fixed;
  width: 125px;
  height: 60px;
  border-radius: 5px;
  left: calc(50vw - 147px);
  top: calc(50vh - 87px);
  transform: rotate(-15deg);
  transform-origin: 60% 0%;
  border: 4px solid #58442d;
  transition: transform 0.3s ease;
}

.notfound .signtext:hover {
  transform: rotate(-20deg);
}

.notfound .signtext > p {
  text-align: center;
  margin: 10px 0px 0px 0px;
  padding: 0;
  font-weight: bold;
  font-size: 13px;
  font-family: Roboto Slab;
  color: rgba(0, 0, 0, 0.8);
}

.notfound .pin {
  width: 5px;
  height: 5px;
  position: fixed;
  background: #9e9e9e;
  border: 1px solid #424242;
  border-radius: 100%;
  left: calc(50vw - 70px);
  top: calc(50vh - 79px);
}

.notfound .spider {
  width: 10px;
  height: 10px;
  background: #161c1f;
  position: absolute;
  border-radius: 100%;
  left: 25px;
  top: 15px;
  transition: transform 0.3s ease;
}

.notfound .leg {
  width: 5px;
  height: 1px;
  position: abolute;
  background: #161c1f;
}

.notfound .left-1 {
  margin-left: -4px;
  transform: rotate(15deg);
  margin-top: 1px;
}

.notfound .left-2 {
  margin-left: -4px;
  transform: rotate(0deg);
  margin-top: 2px;
}

.notfound .left-3 {
  margin-left: -4px;
  transform: rotate(-15deg);
  margin-top: 2px;
}

.notfound .right-1 {
  margin-left: 8px;
  transform: rotate(-15deg);
  margin-top: -6px;
}

.notfound .right-2 {
  margin-left: 8px;
  transform: rotate(0deg);
  margin-top: 2px;
}

.notfound .right-3 {
  margin-left: 8px;
  transform: rotate(15deg);
  margin-top: 2px;
}

.notfound .spdline {
  position: absolute;
  background: #161c1f;
  width: 1px;
  height: 20px;
  left: 30px;
}

.notfound .bowl:hover > .spider {
  transform: translate(0, 5px);
}

.notfound .bowl:hover + .ghost {
  transform: translate(0, -15px);
  opacity: 1;
}

.notfound .ghost:hover {
  transform: translate(0, -15px);
  opacity: 1;
}

.notfound .ghost {
  position: fixed;
  height: 65px;
  width: 50px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 100px 100px 5px 5px;
  left: 15px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  left: calc(50vw + 17px);
  top: calc(50vh - 12px);
  opacity: 0.25;
  z-index: -50;
  box-shadow: inset -5px 0 0 rgba(0, 0, 0, 0.07);
  transition: opacity 0.5s ease, transform 0.3s ease;
}

.notfound .eye {
  position: absolute;
  width: 7px;
  height: 10px;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.5);
}

.notfound .eye-left {
  left: 14px;
  top: 15px;
}

.notfound .eye-right {
  left: 28px;
  top: 15px;
}

.notfound .mouth {
  position: absolute;
  width: 20px;
  height: 13px;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0);
  top: 21px;
  left: 14px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  border-top: 2px solid transparent;
  border-bottom: 2px solid #c9ccce;
}

.notfound .chocolate {
  position: absolute;
  width: 4px;
  height: 7px;
  border-radius: 0px 0px 100% 100%;
  background: #5d4037;
  right: 15px;
  top: 35px;
}

.overflowelip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
}

.nav-profile {
  width: 20rem !important;
}

.map-truckicon {
  background-color: white;
  color: green !important;
  font-size: 16px;
  padding: 5px 5px;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
}

.expense-ul {
  padding: 10px;
  margin: 0;
  list-style-type: none;
  background-color: rgba(4, 209, 130, 0.18);
}

.select-before {
  width: 88px;
}

.select-after {
  width: 80px;
}

[data-theme="compact"] .select-before {
  width: 71px;
}

[data-theme="compact"] .select-after {
  width: 65px;
}

.upload-zone {
  min-height: 50px !important;
}

.expense-table {
  background: rgba(219, 233, 255, 1);
  /* padding:3px 0px; */
}

.expense-table td {
  border-bottom: 1px solid #cbcbcb;
  padding: 3px 0px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pay-popup .ant-form-item {
  margin-bottom: 0px !important;
}

.ant-layout-content {
  height: 100%;
}

.mapboxgl-ctrl-bottom-right {
  display: none;
}

.solid_marker {
  background-size: cover;
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: relative;
  text-align: center;
  line-height: 30px;
  border-radius: 3px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.popuptruck {
  cursor: pointer;
  position: relative;
  text-align: center;
  line-height: 20px;
  border-radius: 3px;
  height: 30px;
}

.solid_marker.pickup_marker {
  background-color: #f5f9ff;
}

.solid_marker.pickup_marker:before {
  font-family: "IcoFont";
  content: "\eef8";
  color: #32b947;
  font-size: 24px;
}

.solid_marker.drop_marker {
  background-color: #fff5f5;
}

.solid_marker.drop_marker:before {
  font-family: "IcoFont";
  content: "\eef7";
  color: #e35252;
  font-size: 24px;
}

.solid_marker.vehicle_marker {
  /* background-color: #fff5f536; */
  background-image: url(/static/media/Truck-PNG.6a0826e8.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px 24px;
}

.truck-status {
  display: inline-block;
  padding: 4px 10px;
  background-color: #000;
  font-size: 10px;
  text-transform: capitalize;
  border-radius: 18px;
  margin-left: 5px;
}

.truck-status.success {
  background-color: rgba(4, 209, 130, 0.1);
  color: #00ab6f;
}

.truck-status.error {
  background-color: #f0959545;
  color: #d54747;
}

.location-popup {
  min-width: 200px;
}

.pick-popup .mapboxgl-popup-content,
.drop-popup .mapboxgl-popup-content,
.location-popup .mapboxgl-popup-content {
  position: relative;
  background-color: #ffffff;
  padding: 10px 10px 10px !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: #ffffff !important;
}

.pick-popup .mapboxgl-popup-content:before {
  content: "\eef8";
  /* this is your text. You can also use UTF-8 character codes as I do here */
  font-family: IcoFont;
  position: absolute;
  top: 20px;
  right: 10px;
  font-size: 60px;
  opacity: 0.1;
  color: #868686;
}

.drop-popup .mapboxgl-popup-content:before {
  content: "\eef7";
  /* this is your text. You can also use UTF-8 character codes as I do here */
  font-family: IcoFont;
  position: absolute;
  top: 20px;
  right: 10px;
  font-size: 60px;
  opacity: 0.1;
  color: #868686;
}

.location-popup .mapboxgl-popup-content:before {
  content: "\ef8a";
  /* this is your text. You can also use UTF-8 character codes as I do here */
  font-family: IcoFont;
  position: absolute;
  top: 20px;
  right: 10px;
  font-size: 60px;
  opacity: 0.1;
  color: #868686;
}

.location-popup .mapboxgl-popup-content > h3,
.pick-popup .mapboxgl-popup-content > h3,
.drop-popup .mapboxgl-popup-content > h3 {
  font-size: 12px;
  margin-bottom: 0px;
  color: #1e3888e0;
  font-weight: 600;
  text-transform: uppercase;
}

.location-popup .mapboxgl-popup-content > p,
.pick-popup .mapboxgl-popup-content > p,
.drop-popup .mapboxgl-popup-content > p {
  font-size: 11px;
  margin-bottom: 0px;
  color: #808080;
}

.mapboxgl-popup-close-button:focus-visible {
  border: 0 !important;
  box-shadow: none !important;
  outline: none;
}

.mapboxgl-map {
  position: relative;
}

.map-sidebar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 280px;
  z-index: 9999;
  background-color: #1e3888e0;
  transition: all 0.3s ease-in-out;
}

.show-disebar {
  position: absolute;
  left: 0;
  top: 0;
  height: 36px;
  width: 36px;
  z-index: 9999;
  background-color: #1e3888e0;
  transition: all 0.3s ease-in-out;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  text-align: center;
  line-height: 36px;
}

.map-sidebar .small_loader {
  float: right;
  width: 20px;
  height: 20px;
}

.map-sidebar .small_arrow {
  float: right;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
  border-radius: 50%;
  /* width: 20px; */
  /* height: 20px; */
  cursor: pointer;
}

.map-sidebar:hover .small_arrow {
  background-color: #fff;
  color: #1e3888e0;
}

.sidebar-head {
  padding: 15px;
  position: fixed;
  height: 70px;
  background-color: #1e3888e0;
  width: 280px;
  z-index: 999;
}

.sidebar-head > h4 {
  padding: 0;
  margin: 0;
  font-size: 16px;
  text-transform: uppercase;
  color: #dbe9ff;
}

.sidebar-head > p {
  padding: 0;
  margin: 0;
  font-size: 12px;
  color: #dbe9ff;
}

.sidebar-body ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-body ul > li > h4 {
  padding: 0;
  margin: 0;
  font-size: 13px;
  color: #dbe9ff;
}

.sidebar-body ul > li > p {
  padding: 0;
  margin: 0;
  font-size: 10px;
}

/*.sidebar-body > .ant-collapse > .ant-collapse-item > .ant-collapse-header{
   color: #DBE9FF;
   background-color: #1E3888E0;
}
.sidebar-body > .ant-collapse > .ant-collapse-content{
    padding: 20px;
}
.sidebar-body .ant-collapse-content-box{
    padding: 0!important;
    margin: 0!important;
}*/
.sidebar-body .shipment-collapse {
  padding: 80px 0px;
}

.sidebar-body .shipment-collapse .ant-collapse-header {
  color: #dbe9ff !important;
  font-size: 13px;
  font-family: "Nunito", sans-serif;
  font-weight: normal;
}

.sidebar-body .shipment-collapse .order-collapse .ant-collapse-item {
  background-color: #1e3888e0;
  margin-bottom: 2px;
}

.ant-collapse > .ant-collapse-item:first-child,
.ant-collapse > .ant-collapse-item:first-child > .ant-collapse-header {
  border-top-right-radius: 0.225rem;
  border-top-left-radius: 0.225rem;
}

.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-bottom-right-radius: 0.225rem;
  border-bottom-left-radius: 0.225rem;
}

.order-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 8px 50px 8px 20px;
}

.order-collapse .ant-collapse-content-box > ul > li {
  margin-bottom: 2px;
  background-color: #1e367d;
  padding: 10px;
  position: relative;
}

/* .order-collapse .ant-collapse-content-box>ul>li>div {
  cursor: pointer;
} */

.order-collapse .ant-collapse-content-box > ul > li > div > h4 {
  padding: 0;
  margin: 0;
  font-size: 12px;
  color: #dbe9ff;
  margin-bottom: 8px;
}

.order-collapse .ant-collapse-content-box > ul > li > div > p {
  padding: 0;
  margin: 0;
  font-size: 8px;
  text-transform: uppercase;
  color: #b7c7e1;
}

.order-collapse .ant-collapse-content-box > ul > li > span {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ffffff;
  padding: 2px 8px;
  font-size: 10px;
  border-radius: 3px;
  text-transform: capitalize;
  color: #000;
}

.sidebar-footer {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.pay-popup .ant-form-item {
  margin-bottom: 0px !important;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 125px;
}

/* .shipment-table :not(:first-child) {
  border-top: none;
} */

.dipatch-document {
  margin: 0 0 5px !important;
}

.dipatch-document .ant-card-body {
  padding: 0px 10px !important;
}

.page-loader {
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 4px 12px;
  background-color: #1e38883d;
  border-radius: 2px;
  font-size: 12px;
  color: #000;
  transition: all 0.3s ease-in-out;
}

.w-100 {
  width: 100%;
}

.ant-upload-list .ant-tooltip-inner {
  display: none !important;
}

.ant-upload-list .ant-tooltip-placement-bottom {
  display: none !important;
}

.truck_marker {
  /* display: block; */
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
  text-align: center;
  line-height: 20px;
  /* border-radius: 4px; */
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.truck_marker.truck_from {
  /* background-color: #485D9A; */
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAABACAYAAAB7jnWuAAAACXBIWXMAAAsSAAALEgHS3X78AAABw0lEQVRoge2Zv0rDUBTGP0WchEYQXNssDh3spjSDGZ0kb2DfQFwyd5NmEH2D+gY+Qh2yOdTBNTo6iYKjEDl4sqS996S3qVE5H3QoPfecX+/5cy/JWp7naFLrjUZfFsAPkp4fJN4yPsQU+EESAogAHALYAfAh+NwC8ALgHsBtlsYTZwA/SIYAjgHsAmgLgct65HWXWRpfmIykFJwAOHAITuryjp3bjCSATYfAZb06AXBxdWsA2HMCANCrIbgoG8BbowBZGk9/AmDDYc07ABMcpa1VN8ADDRQANFCmWRpbU8PFSyDFANu3eqdBZPp0+qO80x+FNhthfUQ+bDZVzoKogo1JoWQwNwW8jQP+KjqxKGJ/Az4XZtI3cxZw8GduQ4+LalvK/Zw/QeP7iYu28NUu+zEVYUht6AfJhBeO/SC5WmA3qEvGAO7w3dIhHd3zDKXTcMLOzioGLuuaOoIATAZVinCZiSiu/dtXsgrypLuENAl7jpeRQmLtSACtRWf7ojIC8Dy4WWVwkm0HaPtPa4ozMP3w77tAARRAARRAARRAARRAARRAARRAARRAAX4/gO0JyWfxpLMpAHpBebRqAH153SwAgC+qkb2hp4nxlgAAAABJRU5ErkJggg==);
}

/*.truck_marker.truck_from:before{
    display: inline-block;
    content: "\26B2";
    color: #ffffff;
    font-size: 30px;
    background-color: #1E3888A1;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}*/
.truck_marker.truck_to {
  /* background-color: #485D9A; */
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAABACAYAAAB7jnWuAAAACXBIWXMAAAsSAAALEgHS3X78AAABw0lEQVRoge2Zv0rDUBTGP0WchEYQXNssDh3spjSDGZ0kb2DfQFwyd5NmEH2D+gY+Qh2yOdTBNTo6iYKjEDl4sqS996S3qVE5H3QoPfecX+/5cy/JWp7naFLrjUZfFsAPkp4fJN4yPsQU+EESAogAHALYAfAh+NwC8ALgHsBtlsYTZwA/SIYAjgHsAmgLgct65HWXWRpfmIykFJwAOHAITuryjp3bjCSATYfAZb06AXBxdWsA2HMCANCrIbgoG8BbowBZGk9/AmDDYc07ABMcpa1VN8ADDRQANFCmWRpbU8PFSyDFANu3eqdBZPp0+qO80x+FNhthfUQ+bDZVzoKogo1JoWQwNwW8jQP+KjqxKGJ/Az4XZtI3cxZw8GduQ4+LalvK/Zw/QeP7iYu28NUu+zEVYUht6AfJhBeO/SC5WmA3qEvGAO7w3dIhHd3zDKXTcMLOzioGLuuaOoIATAZVinCZiSiu/dtXsgrypLuENAl7jpeRQmLtSACtRWf7ojIC8Dy4WWVwkm0HaPtPa4ozMP3w77tAARRAARRAARRAARRAARRAARRAARRAAX4/gO0JyWfxpLMpAHpBebRqAH153SwAgC+qkb2hp4nxlgAAAABJRU5ErkJggg==);
}

.drive {
  display: block;
  position: relative;
  margin: 0px auto;
  width: 50%;
  height: 40px;
  padding: 10px;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  background: #3f51b5;
}

/*.truck_marker.truck_to:before{
    display: inline-block;
    content: "\26B2";
    color: #ffffff;
    font-size: 30px;
    background-color: #1E3888A1;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}*/

.ant-input-group-addon {
  border-radius: 5px;
}

.input-style .ant-input {
  border-radius: 5px;
}

.input-style .ant-picker {
  width: 100%;
  border-radius: 5px !important;
  border: 1px solid green(39, 74, 138, 1) !important;
  background-color: #f6fcff !important;
  font-size: 12px !important;
  height: 40px;
}

.form-innersection .ant-input-affix-wrapper {
  border-radius: 4px;
  border: 1px solid rgba(39, 74, 138, 1);
  background-color: #f6fcff;
  font-size: 14px !important;
  color: rgba(34, 43, 69, 1);
  font-weight: 600;
}

.table {
  vertical-align: middle !important;
}

.mandatory {
  color: rgb(243, 154, 154);
}

.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
  border-radius: 5px !important;
}

.ant-form-item {
  margin-bottom: 15px !important;
}

.ant-input-disabled,
.ant-input[disabled] {
  color: #4d4d4d !important;
  font-weight: bold;
}

.footerhidden .ant-modal-footer {
  display: none;
}

.preview-image .ant-upload-select-picture-card {
  display: none !important;
}

.ant-menu-submenu {
  z-index: 9999 !important;
}

.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-left: 8px;
  display: none !important;
}

.ant-modal-close {
  display: none !important;
}

.ant-tooltip-inner {
  color: rgb(0 0 0) !important;
  background-color: rgb(255 255 255) !important;
}

.export-excel {
  position: absolute;
  bottom: 2%;
  right: 2%;
}

.export-excel2 {
  position: absolute;
  bottom: 3%;
  left: 1%;
}

.ant-input-number-focused,
.ant-input-number:hover {
  border-color: rgb(105, 157, 255);
  border-right-width: 1px !important;
}

.ant-input-number {
  width: auto;
}

.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}

.ant-input-number {
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(39, 74, 138, 1);
  background-color: #f6fcff;
  font-size: 14px !important;
  color: rgba(34, 43, 69, 1);
  font-weight: 600;
}

.ant-input-number-out-of-range input {
  color: rgb(255, 107, 114) !important;
}

.invoiveline {
  border-bottom: 1px solid #555;
  width: 100%;
}

.ant-select-selection-item {
  width: 80px !important;
  padding-right: 35px !important;
}

@media (max-width: 600px) {
  .ant-image-img {
    width: 100px;
  }

  .editptr .symbol {
    margin: 0px !important;
  }

  .ant-modal {
    width: 100% !important;
  }
}

.new1 {
  padding-left: 0px !important;
}

.ant-dropdown-placement-bottomRight {
  position: fixed !important;
}

.ant-upload.ant-upload-select-picture-card {
  width: 110px;
  height: 110px;
  border: transparent !important;
  border-radius: 10px;
  background-color: transparent;
  margin-right: 10px;
  margin-bottom: 10px;
}

.ant-upload.ant-upload-select-picture-card:hover {
  border-color: transparent !important;
}

.pointer {
  cursor: pointer;
}

.ant-upload-text {
  font-size: 16px;
  padding: 25px;
  margin: 10px;
}

.uploadfile {
  margin-top: 20px;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.02);
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.ant-upload {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  color: rgb(69, 85, 96);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  outline: 0px;
  width: 100%;
}

.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background-color: #fff;
  border-color: rgba(39, 74, 138, 1) !important;
}

.ant-form-item-has-error .ant-input-prefix {
  color: rgba(39, 74, 138, 1) !important;
}

.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper:hover,
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background-color: #fff;
  border-color: rgba(39, 74, 138, 1) !important;
}

.ant-form-item-explain.ant-form-item-explain-error {
  color: rgb(228, 18, 18);
  font-size: 12px;
  font-weight: bold;
}

.ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #fff;
  border-color: rgba(39, 74, 138, 1) !important;
}

.customtimepicker .form-select {
  background-image: none !important;
  padding: 9%;
  width: auto;
  border-radius: 0px !important;
}

.timedrop {
  display: none;
}

.customtimepicker:hover .timedrop {
  display: block;
}
.order-view-title .ant-descriptions-title {
  color: white !important;
  background: #831639 !important;
  padding: 10px !important;
}

.expense-data .ant-descriptions-title {
  color: black;
  background: grey !important;
  padding: 10px !important;
}
.expense-data .expense-data .ant-descriptions-title {
  color: black;
  background: rgb(124, 159, 182) !important;
  padding: 10px !important;
}
.expense-data .ant-descriptions-title {
  color: black;
  background: grey !important;
  padding: 10px !important;
}

.ship-dispatch {
  padding-right: "88px" !important;
}

.autocompleteCtr .pac-target-input {
  padding: 8.5px 14px;
  height: 1.4375em;
  border: 1px solid #979797;
  border-radius: 5px;
  /* width: 95%; */
}

.expand-row-color {
  font-size: 13px !important;
  color: black !important;
}

.timedrop .form-control {
  border-radius: 0px !important;
}

.timepicker-button-new {
  border: none !important;
}

.timepicker-button-new1 {
  border: none !important;
  margin-bottom: 10px;
}

.form-select-new {
  font-size: 12px !important;
}

.new-dispatch-view {
  color: black;
  font-weight: 600;
  margin-top: 10px;
}

.new-dispatch-select {
  margin-left: 10px;
}

.shipments-list1 {
  padding: 20px 0px 0px;
  margin: 0;
  list-style-type: none;
}

.shipments-list1 > li {
  padding: 10px 20px 10px;
  position: relative;
}

.shipments-list1 > li:before {
  content: "";
  position: absolute;
  left: -6px;
  width: 14px;
  height: 14px;
  /* background-color: #ddd; */
  border-radius: 50%;
}

.shipments-list1 > li.shipment-delivered {
  background-color: rgba(219, 233, 255, 1);
}

.shipments-list1 > li.shipment-returned {
  background-color: rgba(68, 100, 144, 1);
}

.shipments-list1 > li.shipment-delivered:before {
  /* background-color: rgba(68, 100, 144, 1.0); */
}

.shipments-list1 > li.shipment-returned:before {
  background-color: #ff6b72;
}

.order-info h6,
.shipments-list1 > li .list-card h6 {
  padding: 0;
  margin: 0;
  font-size: 10px;
  text-transform: uppercase;
  color: #999;
}

.order-info p,
.shipments-list1 > li .list-card p {
  padding: 0;
  margin: 0;
  font-size: 12px;
  color: #000;
}

.dispatch-view-driverupdate {
  border: 1px solid #999;
  border-radius: 5px;
}

.dispatch-view-driverupdate1 {
  grid-gap: 10px;
  gap: 10px;
}

.dispatch-width {
  width: 40% !important;
}

.shipment-width {
  width: 25% !important;
}

.new-select-mulitple .ant-select-selection-item {
  width: 250px !important;
}

.eye-icon-button.ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):not(
    .ant-btn-text
  ):not(.ant-btn-link):focus {
  background-color: #fff !important;
}

.table-order-new
  .ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-text):not(
    .ant-btn-link
  ):hover {
  background-color: #fff !important;
}

.new-select .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: white !important;
  border: 1px solid #e6ebf1 !important;
}

